/**
 * Provides a Vue plugin for accessing an API.
 **/
import config from "@/config.js";

let _backendServer;
let _apiBase;

function updateBackendServer() {
  if (
    window.location.host === "intern.gymkirchenfeld.ch" ||
    window.location.host === "admin.gymkirchenfeld.ch"
  ) {
    _backendServer = config.backendServer.prod;
  } else if (localStorage.getItem("localBackend") === "true") {
    _backendServer = config.backendServer.dev;
  } else {
    _backendServer = config.backendServer.test;
  }
  _apiBase = _backendServer + "api/";
  console.log(_apiBase);
}

updateBackendServer();

/* https://gist.github.com/devloco/5f779216c988438777b76e7db113d05c */
const FILENAME = `filename*=UTF-8''`;
const FILENAME2 = `filename=`;
const DEFAULT_FILENAME = "download.pdf";
function extractFileName(header) {
  if (!header) return DEFAULT_FILENAME;
  const parts = header.split(";");
  for (let i = 0; i < parts.length; ++i) {
    let part = parts[i].trim();
    if (part.indexOf(FILENAME) === 0) {
      return decodeURIComponent(part.substring(FILENAME.length));
    }
    if (part.indexOf(FILENAME2) === 0) {
      return decodeURIComponent(part.substring(FILENAME2.length));
    }
  }
  return DEFAULT_FILENAME;
}

export default {
  install(Vue) {
    Vue.mixin({
      computed: {
        apiIsProd() {
          return _backendServer === config.backendServer.prod;
        },
        $_isAdminApp() {
          return "admin" === config.app;
        },
        $_isInternApp() {
          return "intern" === config.app;
        },
      },
      methods: {
        async apiDelete(params) {
          let path = `${params.resource}/${params.id}`;
          if (params.query) path += `?${params.query}`;
          return await this.apiRequest({
            method: "DELETE",
            path: path,
          });
        },
        async apiGet(params) {
          let path = `${params.resource}/${params.id}`;
          if (params.query) path += `?${params.query}`;
          return await this.apiRequest({
            method: "GET",
            path: path,
            onAbort: params.onAbort,
            onError: params.onError,
            onSuccess: params.onSuccess,
            signal: params.signal,
          });
        },
        async apiList(params) {
          let path = params.resource;
          if (params.query) path += `?${params.query}`;
          return await this.apiRequest({
            method: "GET",
            path: path,
            onAbort: params.onAbort,
            onError: params.onError,
            onSuccess: params.onSuccess,
            defaultValue: params.defaultValue || [],
            signal: params.signal,
          });
        },
        async apiPatch(params) {
          const path = `${params.resource}/${params.id}`;
          const body = {};
          body[params.key] = params.value;
          return await this.apiRequest({
            method: "PATCH",
            path: path,
            body: body,
            onAbort: params.onAbort,
            onError: params.onError,
            onSuccess: params.onSuccess,
            signal: params.signal,
          });
        },
        async apiPost(params) {
          return await this.apiRequest({
            method: "POST",
            path: params.resource,
            body: params.data,
            onAbort: params.onAbort,
            onError: params.onError,
            onSuccess: params.onSuccess,
            signal: params.signal,
          });
        },
        async apiPut(params) {
          let path = params.resource;
          if (params.data.id) path += `/${params.data.id}`;
          return await this.apiRequest({
            method: "PUT",
            path: path,
            body: params.data,
            onAbort: params.onAbort,
            onError: params.onError,
            onSuccess: params.onSuccess,
            signal: params.signal,
          });
        },
        async apiSave(params) {
          return params.add
            ? await this.apiPost(params)
            : await this.apiPut(params);
        },
        async apiUploadPost(params) {
          const path = `${params.resource}`;
          return await this.apiRequest({
            method: "POST",
            path: path,
            noJson: true,
            body: new FormData(params.form),
          });
        },
        async apiUploadPut(params) {
          const path = `${params.resource}/${params.data.id}`;
          const formData = new FormData();
          formData.append("file", params.file, params.fileName);
          return await this.apiRequest({
            method: "PUT",
            path: path,
            noJson: true,
            body: formData,
          });
        },
        async apiDownload(params) {
          const options = await this._createOptions("GET");
          const url = `${_apiBase}${params.resource}/${params.id}`;
          const response = await fetch(url, options);
          if (response.status !== 200) {
            console.log(options);
            return;
          }
          let link = document.createElement("a");
          link.download = extractFileName(
            response.headers.get("content-disposition")
          );
          link.href = window.URL.createObjectURL(await response.blob());
          link.click();
        },

        async apiBlob(params) {
          const headers = new Headers();
          const accessToken = await this.$auth.getToken();
          if (!accessToken) {
            return params.defaultValue;
          }

          headers.append("Authorization", `Bearer ${accessToken}`);
          const options = {
            //cache: 'no-cache',
            headers: headers,
            method: "GET",
            signal: params.signal,
          };
          const url = `${_apiBase}${params.path}`;
          try {
            const response = await fetch(url, options);
            if (!response.ok) {
              return params.defaultValue;
            }

            if (response.status === 204) return;
            const blob = await response.blob();
            if (blob.size === 0) return null;
            const blobUrl = URL.createObjectURL(blob);
            return blobUrl;
          } catch (error) {
            if (error.name === "AbortError") return;
            console.error(error);
            return params.defaultValue;
          }
        },
        async _waitForReport(id, params) {
          const options = await this._createOptions("GET");
          const url = `${_apiBase}app/reportjob/${id}`;
          const response = await fetch(url, options);
          if (response.status !== 200) {
            params.onError();
            return;
          }
          const contentType = response.headers.get("content-type");
          if (contentType === "application/json") {
            const json = await response.json();
            params.onProgress(json.result.progress);
            setTimeout(this._waitForReport, 250, id, params);
          } else {
            const blob = await response.blob();
            const fileName = extractFileName(
              response.headers.get("content-disposition")
            );
            const result = {
              blob: blob,
              fileName: fileName,
              download() {
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(this.blob);
                link.download = this.fileName;
                link.click();
              },
              open() {
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(this.blob);
                link.click();
              },
              openInNew() {
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(this.blob);
                link.target = "_blank";
                link.click();
              },
            };
            params.onDownload(result);
          }
        },
        async apiDocument(params) {
          const headers = new Headers();
          const accessToken = await this.$auth.getToken();

          headers.append("Authorization", `Bearer ${accessToken}`);
          const options = {
            //cache: 'no-cache',
            headers: headers,
            method: "GET",
            signal: params.signal,
          };
          let url = `${_apiBase}document/file/${params.id}`;

          const response = await fetch(url, options);
          let link = document.createElement("a");
          if (params.download) {
            link.download = extractFileName(
              response.headers.get("content-disposition")
            );
          }
          if (params.openInNew) {
            link.target = "_blank";
          }
          link.href = window.URL.createObjectURL(await response.blob());
          link.click();
        },
        async apiIcal(resource, id) {
          const headers = new Headers();
          const accessToken = await this.$auth.getToken();

          headers.append("Authorization", `Bearer ${accessToken}`);
          const options = {
            //cache: 'no-cache',
            headers: headers,
            method: "GET",
          };
          let url = `${_apiBase}ical/${resource}`;
          if (id) url += "/" + id;
          const response = await fetch(url, options);
          let link = document.createElement("a");
          link.download = extractFileName(
            response.headers.get("content-disposition")
          );
          link.href = window.URL.createObjectURL(await response.blob());
          link.click();
        },
        async apiReport(params) {
          if (params.openInNew) {
            params.onDownload =
              params.onDownload ||
              function (file) {
                file.openInNew();
              };
          } else if (params.download) {
            params.onDownload =
              params.onDownload ||
              function (file) {
                file.download();
              };
          } else {
            params.onDownload =
              params.onDownload ||
              function (file) {
                file.open();
              };
          }

          params.onError = params.onError || function () {};
          params.onProgress = params.onProgress || function () {};

          let url = `${params.resource}`;
          //if (params.id) url += `/${params.id}`;
          const response = await this.apiRequest({
            method: "POST",
            path: url,
            body: params.data,
          });
          if (response) {
            params.onProgress(response.progress);
            setTimeout(this._waitForReport, 100, response.id, params);
          }
          return response;
        },

        async apiRequest(params) {
          if (params.onError) {
            const theFunction = params.onError;
            params.onError = async (response) => {
              const msg = await response.text();
              theFunction(msg);
            };
          } else {
            params.onError = this.handleError;
          }
          params.onSuccess = params.onSuccess || function () {};
          params.onAbort = params.onAbort || function () {};
          const options = await this._createOptions(params.method);
          if (!options) return params.defaultValue;

          if (params.noJson) {
            options.body = params.body;
          } else {
            options.body = JSON.stringify(params.body);
            options.headers.append("Content-Type", "application/json");
          }
          if (params.signal) {
            options.signal = params.signal;
          }
          const url = `${_apiBase}${params.path}`;
          try {
            const response = await fetch(url, options);
            if (!response.ok) {
              params.onError(response);
              return params.defaultValue;
            }
            params.onSuccess();
            if (response.status === 204) return;
            const contentType = response.headers.get("content-type");
            if (!contentType) return;
            return (await response.json()).result;
          } catch (error) {
            if (error.name === "AbortError") {
              params.onAbort();
              return;
            }
            console.error(error);
            //            this.$root.noConnection();
            return params.defaultValue;
          }
        },
        async _createOptions(method) {
          const headers = new Headers();
          const accessToken = await this.$auth.getToken();
          if (!accessToken) return;
          headers.append("Authorization", `Bearer ${accessToken}`);
          return {
            //cache: 'no-cache',
            headers: headers,
            method: method,
          };
        },
        async handleError(response) {
          const message = await response.text();
          if (message) {
            this.$root.showError(
              `Fehler ${response.status}:<br><strong>${message}</strong>`
            );
          } else {
            this.$root.showError(`Fehler ${response.status}`);
          }
        },
        apiBase() {
          return _apiBase;
        },
        backendServer() {
          return _backendServer;
        },
        isDev() {
          return _backendServer === config.backendServer.dev;
        },
        isLocalBackend() {
          return localStorage.getItem("localBackend") === "true";
        },
        isProd() {
          return _backendServer === config.backendServer.prod;
        },
        setLocalBackend(enable) {
          localStorage.setItem("localBackend", enable);
          updateBackendServer();
        },
      },
    });
  },
};
